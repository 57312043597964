<template>
  <g>
    <svg:style>
      .cls-1, .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-width: 0.25px;
      }

      .cls-1, .cls-2, .cls-3 {
        stroke-miterlimit: 10;
      }

      .cls-1 {
        fill: url(#BG-gradient);
      }

      .cls-2 {
        fill: none;
      }

      .cls-3 {
        fill: url(#handle-gradient);
      }

      .cls-10, .cls-11, .cls-12, .cls-13, .cls-14, .cls-15, .cls-16, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-9 {
        fill: url(#inox-gradient-6);
      }

      .cls-10 {
        fill: url(#inox-gradient-7);
      }

      .cls-11 {
        fill: url(#inox-gradient-8);
      }

      .cls-12 {
        fill: url(#inox-gradient-9);
      }

      .cls-13 {
        fill: url(#glass-pattern);
      }

      .cls-14 {
        fill: url(#glass-pattern);
      }

      .cls-15 {
        fill: url(#glass-pattern);
      }

      .cls-16 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient
      id="handle-gradient"
      :x1="doorLeftWidth1 + 11.33"
      :y1="doorTopHeight1 + 150.96"
      :x2="doorLeftWidth1 + 17.01"
      :y2="doorTopHeight1 + 150.96" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#97999d"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a7aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient
      id="inox-gradient"
      :x1="doorLeftWidth1 + 38.44"
      :y1="doorTopHeight1 + 39.71"
      :x2="doorLeftWidth1 + 40.25"
      :y2="doorTopHeight1 + 39.71"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-2"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 38.44"
      :y1="doorTopHeight1 + 98.86"
      :x2="doorLeftWidth1 + 40.24"
      :y2="doorTopHeight1 + 98.86"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-3"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 38.44"
      :y1="doorTopHeight1 + 151.21"
      :x2="doorLeftWidth1 + 40.24"
      :y2="doorTopHeight1 + 151.21"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-4"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 38.44"
      :y1="doorTopHeight1 + 203.77"
      :x2="doorLeftWidth1 + 40.24"
      :y2="doorTopHeight1 + 203.77"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-5"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 38.44"
      :y1="doorTopHeight1 + 262.93"
      :x2="doorLeftWidth1 + 40.25"
      :y2="doorTopHeight1 + 262.93"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-6"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 70.93"
      :y1="doorTopHeight1 + 88.03"
      :x2="doorLeftWidth1 + 119.67"
      :y2="doorTopHeight1 + 88.03"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-7"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 70.93"
      :y1="doorTopHeight1 + 140.38"
      :x2="doorLeftWidth1 + 119.67"
      :y2="doorTopHeight1 + 140.38"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-8"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 70.93"
      :y1="doorTopHeight1 + 192.91"
      :x2="doorLeftWidth1 + 119.67"
      :y2="doorTopHeight1 + 192.91"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="inox-gradient-9"
      data-name="inox-gradient"
      :x1="doorLeftWidth1 + 70.93"
      :y1="doorTopHeight1 + 245.26"
      :x2="doorLeftWidth1 + 119.67"
      :y2="doorTopHeight1 + 245.26"
      xlink:href="#handle-gradient"/>
    <linearGradient
      id="glass-gradient"
      :x1="doorLeftWidth1 + 54.69"
      :y1="doorTopHeight1 + 88.93"
      :x2="doorLeftWidth1 + 54.69"
      :y2="doorTopHeight1 + 56.44"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f5"/>
      <stop offset="0.5" stop-color="#dde7e6"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient
      id="glass-gradient-2"
      data-name="glass-gradient"
      :x1="doorLeftWidth1 + 54.69"
      :y1="doorTopHeight1 + 141.29"
      :x2="doorLeftWidth1 + 54.69"
      :y2="doorTopHeight1 + 108.79"
      xlink:href="#glass-gradient"/>
    <linearGradient
      id="glass-gradient-3"
      data-name="glass-gradient"
      :x1="doorLeftWidth1 + 54.69"
      :y1="doorTopHeight1 + 246.2"
      :x2="doorLeftWidth1 + 54.69"
      :y2="doorTopHeight1 + 213.63"
      xlink:href="#glass-gradient"/>
    <linearGradient
      id="glass-gradient-4"
      data-name="glass-gradient"
      :x1="doorLeftWidth1 + 54.69" 
      :y1="doorTopHeight1 + 193.85"
      :x2="doorLeftWidth1 + 54.69"
      :y2="doorTopHeight1 + 161.28"
      xlink:href="#glass-gradient"/>

    <g id="S08">
      <rect
        id="inox"
        v-if="showInox"
        class="cls-4"
        :x="doorLeftWidth + 38.44"
        :y="inoxY"
        width="1.81"
        :height="inoxH"/>
      <rect
        id="inox-6"
        v-if="showInox"
        data-name="inox"
        class="cls-9"
        :x="inoxX"
        :y="doorTopHeight1 + 87.13"
        :width="inoxW"
        height="1.81"/>
      <rect
        id="inox-7"
        v-if="showInox"
        data-name="inox"
        class="cls-10"
        :x="inoxX"
        :y="doorTopHeight1 + 139.48"
        :width="inoxW"
        height="1.81"/>
      <rect
        id="inox-8"
        v-if="showInox"
        data-name="inox"
        class="cls-11"
        :x="inoxX"
        :y="doorTopHeight1 + 191.97"
        :width="inoxW"
        height="1.87"/>
      <rect
        id="inox-9"
        v-if="showInox"
        data-name="inox"
        class="cls-12"
        :x="inoxX"
        :y="doorTopHeight1 + 244.32"
        :width="inoxW"
        height="1.87"/>
      <polyline
        id="glass"
        class="cls-13"
        :points="`
        ${inoxX + 32} ${doorTopHeight1 + 56.44} ${inoxX} ${doorTopHeight1 + 56.44} ${inoxX} ${doorTopHeight1 + 88.94} ${inoxX + 32} ${doorTopHeight1 + 88.94} ${inoxX + 32} ${doorTopHeight1 + 56.44}`
        "/>
      <polyline
        id="glass-2"
        data-name="glass"
        class="cls-14"
        :points="`
        ${inoxX + 32} ${doorTopHeight1 + 108.79} ${inoxX} ${doorTopHeight1 + 108.79} ${inoxX} ${doorTopHeight1 + 141.29} ${inoxX + 32} ${doorTopHeight1 + 141.29} ${inoxX + 32} ${doorTopHeight1 + 108.79}
        `"/>
      <polyline
        id="glass-3"
        data-name="glass"
        class="cls-15"
        :points="`
        ${inoxX} ${doorTopHeight1 + 246.2} ${inoxX + 32} ${doorTopHeight1 + 246.2} ${inoxX + 32} ${doorTopHeight1 + 213.63} ${inoxX} ${doorTopHeight1 + 213.63} ${inoxX} ${doorTopHeight1 + 246.2}
        `"/>
      <polyline
        id="glass-4"
        data-name="glass"
        class="cls-16"
        :points="`
        ${inoxX} ${doorTopHeight1 + 193.85} ${inoxX + 32} ${doorTopHeight1 + 193.85} ${inoxX + 32} ${doorTopHeight1 + 161.28} ${inoxX} ${doorTopHeight1 + 161.28} ${inoxX} ${doorTopHeight1 + 193.85}
        `"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
    }
  },
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
    inoxY() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    inoxH() {
      return this.showBg ?
        (this.doorHeight - this.inoxOffset * 2) :
        (this.doorHeight - this.leafOffset * 2)
    },
    inoxX() {
      return this.doorLeftWidth + 38.44
    },
    inoxW() {
      return this.showBg ?
        (this.doorWidth - this.inoxOffset) - 38.44:
        (this.doorWidth - this.leafOffset) - 38.44
    },
  }
}
</script>
